import styled from 'styled-components'
import { Box } from 'reflexbox'

const Container = styled(Box)`
  max-width: 1060px;
  z-index: 200;
  position: relative;
`
Container.defaultProps = {
  mx: 'auto',
  px: 4
}

export default Container